<template>
    <v-card class="ma-2" style="text-align: center;">
        <page-title title="Reports" icon="mdi-equal-box"/>
        <v-container fluid grid-list-md px-0 py-0>
            <v-row>
                <v-col :key="key" md="3" sm="6" xs="12" v-for="(item,key ) in reportItems">
                    <v-card
                            :color="item.color"
                            class="mx-auto text-sm-center"
                            max-width="100%"
                            :style="{'background-color': item.color + ' !important'}"
                    >
                        <v-card-text class="white--text" style="padding: 0px; margin: 0px">
                            <div style="padding: 12px">
                                <div class="text-sm-center text-xs-center">
                                    <v-icon class="icon-text">{{item.icon}}</v-icon>
                                </div>
                                <div class="btn-text text-sm-center text-xs-center">{{item.title}}</div>
                            </div>
                            <v-divider/>
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                                large
                                                :color="item.color"
                                                class="icon-text widget-btn"
                                                @click="onClickReportsHandler(item.param)"
                                                v-on="on"
                                        >
                                            <v-icon right>visibility</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{'Click to load ' + item.title + ' report'}}</span>
                                </v-tooltip>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import PageTitle from "../../components/PageTitle";
    export default {
        name: "Invoices",
        metaInfo: {
            title: 'webexpressmedia.com',
            titleTemplate: 'Admin Dashboard - Invoices | %s',
            meta: [
                { name: 'robots', content: 'noindex' },
                { name: 'googlebot', content: 'noindex' }
            ]
        },
        components: {
            PageTitle
        },
        data() {
            return {
                reportItems: [
                    {
                        title: 'All',
                        param: 'all',
                        icon: 'mdi-card-account-details-star',
                        color: 'rgba(3,75,75,0.9)'
                    },
                    {
                        title: 'With Payment Mail',
                        param: 'paymentMail',
                        icon: 'mdi-card-account-details-star',
                        color: 'rgba(247, 105, 33, 0.9)'
                    },
                    {
                        title: 'With Removal Mail',
                        param: 'removalMail',
                        icon: 'mdi-email-alert',
                        color: 'rgba(107, 117, 21, 0.9)'
                    },
                    {
                        title: 'With After Removal Mail',
                        param: 'afterRemovalMail',
                        icon: 'mdi-email-lock',
                        color: 'rgba(5, 22, 86, 0.9)'
                    }
                ]
            }
        },
        methods: {
            onClickReportsHandler(param) {
                this.$router.push({
                    path: 'invoice/invoice-by-mail',
                    query: {mailType: param}
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .icon-text {
        font-size: 60px;
        color: white;
        .v-icon {
            font-size: 24px !important;
        }
    }

    .btn-text {
        font-size: 25px;
    }

    .widget-btn {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
</style>